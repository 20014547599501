import * as React from 'react';

import { Page } from '@circleci/analytics';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { defineMessages, useIntl } from 'react-intl';

import Hero from '~/components/Hero';
import Main from '~/components/Main';

const headContent = defineMessages({
  title: {
    id: 'vcsAuthorizePage.title',
    defaultMessage: 'Log in - CircleCI',
  },
  metaTitle: {
    id: 'vcsAuthorizePage.metaTitle',
    defaultMessage: 'Log in to access CircleCI',
  },
  metaDescription: {
    id: 'vcsAuthorizePage.metaDescription',
    defaultMessage:
      'Log into your CircleCI account to view the status of your builds.',
  },
});

export default (): React.ReactElement => {
  const router = useRouter();
  const { pathname, query } = router;
  const intl = useIntl();

  return (
    <>
      <Head>
        <title>{intl.formatMessage(headContent.title)}</title>
        <meta
          name='title'
          content={intl.formatMessage(headContent.metaTitle)}
        />
        <meta
          name='description'
          content={intl.formatMessage(headContent.metaDescription)}
        />
        <link
          rel='alternate'
          hrefLang='x-default'
          href={`https://circleci.com${pathname}/`}
        />
        <link
          rel='alternate'
          hrefLang='en'
          href={`https://circleci.com${pathname}/`}
        />
        <link
          rel='alternate'
          hrefLang='ja'
          href={`https://circleci.com/ja${pathname}/`}
        />
        <link
          rel='alternate'
          hrefLang='ko'
          href={`https://circleci.com/ko${pathname}/`}
        />
      </Head>
      <Page
        pageType='outer'
        pageName='login'
        teamName='ftux'
        orgSlug={null}
      >
        <Main page='vcsAuthorize'>
          <Hero
            page='vcsAuthorized'
            isSessionExpired={!!query['return-to']}
            isEmailVerifiedEvent={!!query['email-verified']}
          />
        </Main>
      </Page>
    </>
  );
};
